<template>
  <div class="d-inline-block" style="max-width: 100%; width: 100%;">
    <v-hover v-slot:default="{ hover }">
      <v-avatar :class="{ 'rounded' : rounded }" :tile="tile" :color="color" :size="size" @click="selectUser()">
        <v-img :class="{ 'rounded' : rounded, 'blur-image': (!currentUser || blur) && url !== '/user-placeholder.jpg' }" :src="url" alt="profile photo" />

        <v-overlay v-if="hover && editable" absolute>
          <v-btn icon @click="openDialog()">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-overlay>
      </v-avatar>
    </v-hover>

    <template v-if="editable">
      <v-dialog
        v-model="status.uploadPhotoDialog"
        max-width="450"
        persistent
      >
        <v-card>
          <v-card-title class="font-weight-bold">Upload Photo</v-card-title>
          <v-card-text>
            <v-form ref="photoForm">
              <v-progress-linear
                v-if="status.uploadProgress" 
                :value="status.uploadProgress"
                height="4"
                color="primary"
                class="mb-2"
                reactive
              ></v-progress-linear>

              <v-file-input
                v-model="data.avatar"
                placeholder="Select Photo"
                prepend-icon=""
                prepend-inner-icon="mdi-account-box"
                accept="image/*"
                ref="photoInput"
                :rules="[rules.required]"
                outlined
              ></v-file-input>

              <v-btn
                @click="validateForm()"
                :loading="status.uploadingPhoto"
                color="primary"
                class="mr-2"
                depressed
                dark
              >Upload</v-btn>

              <v-btn
                @click="closeDialog()"
                :disabled="status.uploadingPhoto"
                depressed
              >Cancel</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import db from '@/firebase/init'
import firebase from 'firebase'
import rules from '@/rules'

export default {
  name: 'UserPhoto',

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    id: String,
    size: null,
    blur: Boolean,
    tile: Boolean,
    rounded: Boolean,
    editable: Boolean,
    photoSize: String,
    enableSelection: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      photoUrl: null,
      user: null,
      color: null,
      colors: [
        'pink',
        'purple',
        'deep-purple',
        'indigo',
        'blue',
        'light-blue',
        'cyan',
        'teal',
        'green',
        'light-green',
        'orange',
        'deep-orange',
        'blue-grey',
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.user.data,
      status: state => state.user.status,
      currentUser: state => state.user.user,
      photos: state => state.users.photos,
    }),

    url: function () {
      let photo = this.photos.find(p => p.id == this.id)
      return photo ? photo.url : '/user-placeholder.jpg'
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'currentUser.avatar': function () {
      this.getUrl(this.id)
    },

    'id': function (val) {
      this.getUrl(val)
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('user', [
      'uploadPhoto'
    ]),

    getInitial(name) {
      let nameArray = name.split("")
      return nameArray[0]
    },

    openDialog() {
      this.$store.commit('user/setUploadPhotoDialog', true)
    },

    closeDialog() {
      this.$store.commit('user/setUploadPhotoDialog', false)
      this.$refs.photoForm.reset()
    },

    validateForm() {
      if (this.$refs.photoForm.validate()) {
        this.uploadPhoto()
      }
    },

    getUrl(id) {
      if (!this.photos.find(p => p.id == id)) {
        db.collection('users')
        .where('userid', '==', id)
        .limit(1).get()
        .then(snapshot => {
          if (snapshot.size) {
            let data = snapshot.docs[0]
            this.user = data.data()
            this.user.id = data.id
            this.user.ref = data.ref
  
            if (this.user.avatar) {
              var storage  = firebase.storage()
  
              storage.ref(`profilephotos/${this.user.avatar}`)
              .getDownloadURL()
              .then(url => {
                this.$store.commit('users/insertPhotos', { id, url })  
              })
            }
          }
        })
        .catch(error => {
          console.log(error.message)
        })
      }
    },

    getRandomColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)]
    },

    selectUser() {
      if(this.enableSelection) {
        this.$emit('userSelect')
      }
    }
  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getUrl(this.id)
    this.color = this.getRandomColor()
  }

}

</script>

<style>

.blur-image {
  filter: blur(5px);
}

</style>
